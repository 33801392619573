<template>
    <div class="second-bloc mb-4">
        <h5 class="fsb-typo-heading-5">{{ $t('chooseDiffusionArea') }}</h5>
        <label for="tags-zipCode" class="sub-title fsb-typo-para-regular">
            {{ $t('choiceDiffusionArea') }}
            <span>●</span>
        </label>
        <b-form-tags
            input-id="tags-zipCode"
            :placeholder="$t('enterYourPostCode')"
            separator=" ,;"
            :tag-validator="tagValidator"
            v-model="zipCodesTags"
            :duplicate-tag-text="t('zipCodeDuplicateRule')"
            @input="onTagAdded"
            :invalid-tag-text="t('zipCodeFormatRule')"
        >
        </b-form-tags>
    </div>
</template>

<script setup lang="ts">
    import { useAccessDataStore } from '@/store/accessData';
    import { storeToRefs } from 'pinia';
    import { useI18n } from 'vue-i18n';

    const { t } = useI18n({ useScope: 'global' });

    const accessData = useAccessDataStore();

    let { zipCodes } = storeToRefs(accessData);
    let zipCodesTags = [''] as string[];
    let lastEventDate = 0;

    const onTagAdded = (tags: string[]) => {
        zipCodes.value = tags;
    };

    const tagValidator = (tag: string) => {
        // Individual tag validator function
        const validTag = !!tag.match(/^\d{5}$/);
        if (validTag) triggerEnterKey();
        return validTag;
    };

    const triggerEnterKey = () => {
        const currentDate = new Date().getTime();
        const diff = currentDate - lastEventDate;
        if (diff < 300) return;
        lastEventDate = currentDate;
        const input = document.querySelector('#tags-zipCode');
        if (input) {
            const event = new KeyboardEvent('keydown', {
                key: 'Enter',
                code: 'Enter',
                keyCode: 13,
                which: 13,
            });
            input.dispatchEvent(event);
        }
    };
</script>

<style lang="scss" scoped>
    .second-bloc {
        :deep(.b-form-tags) {
            border-radius: 8px;
            width: 468px;
            border: 1px;
            padding: 13px;

            div[aria-live='polite'] .form-text.text-muted {
                color: #dc3545 !important;
            }

            .b-form-tags-button {
                display: none !important;
            }
            .b-form-tags-list {
                gap: 0.25rem;
                .b-form-tag {
                    padding: 8px 12px;
                    background: #eff5ff;
                    border-radius: 8px;
                    span {
                        color: #1f1f1f;
                        size: 10px;
                        font-weight: 400;
                    }
                    button {
                        color: #1f1f1f;
                    }
                }
                .b-form-tags-button {
                    display: none !important;
                }
                .b-form-tags-input {
                    font-size: 12px !important;
                    color: #707070;
                }
            }
        }
    }
</style>
