import { axiosInstance } from '@/axiosInstance';
import { formatedDateToBack } from '@/helpers/date';
import { defineStore } from 'pinia';
import { CtlTypes } from '@adcleek/db-types';
import { useAccessDataStore, useAccessOperationsStore, useGlobalStore } from './storeIndex';

export const usePurchaseCampaignsStore = defineStore('usePurchaseCampaignsStore', {
    state: () => ({
        quotation: {} as {
            quotationCode: string;
            billCode: string;
            paymentCode: string;
            couponCode: string[];
            parentCampaignCode: string;
            childCampaignCode: string[];
        },
    }),
    getters: {},
    actions: {
        async purchaseCampaigns(token: string) {
            const accessDataStore = useAccessDataStore();
            const accessOperationsStore = useAccessOperationsStore();
            const globalStore = useGlobalStore();

            const brand = globalStore.brand;
            const mediasCode = accessDataStore.getMediasToDisplay;
            const url = `/users/access-operations/purchase`;
            const campaigns = [] as CtlTypes.QuotationApiCreateCampaignsCtlSub[];

            mediasCode.forEach((media: string) => {
                campaigns.push({
                    mediaCode: media,
                    amount: Math.round(accessDataStore.budget / mediasCode.length),
                    volume: Math.round(accessDataStore.impressionsCount / mediasCode.length),
                    startDate: formatedDateToBack(accessDataStore.getCampaignStartDate),
                    endDate: formatedDateToBack(accessDataStore.getCampaignEndDate),
                    creaFormat: 1,
                    sms: null,
                });
            });

            campaigns[0].amount += accessDataStore.budget % mediasCode.length;
            campaigns[0].volume += accessDataStore.impressionsCount % mediasCode.length;

            const purchaseData = {
                brand,
                posCode: accessOperationsStore.accessOperations.pos_code,
                token,
                zipCodes: accessDataStore.zipCodes,
                operations: accessDataStore.selectedOperations.map((so) => {
                    return { code: so.code, name: so.name };
                }),
                campaigns: campaigns,
            };
            try {
                const res = await axiosInstance.post(url, purchaseData);
                this.quotation = res.data;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
    },
});
