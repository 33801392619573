import { axiosInstance } from '@/axiosInstance';
import { User } from '@adcleek/db-types';
import { defineStore } from 'pinia';
import { useGlobalStore } from './GlobalStore';
import { useAccessDataStore } from './accessData';

export const useAccessOperationsStore = defineStore('useAccessOperationsStore', {
    state: () => ({
        accessOperations: {} as User.AccessOperationsDb,
    }),
    getters: {},
    actions: {
        async getAccessOperations(token: string) {
            const GlobbalStore = useGlobalStore();
            const AccessStore = useAccessDataStore();
            const brand = GlobbalStore.brand;
            const url = `/users/access-operations/brands/${brand}/token/${token}`;
            try {
                const res = await axiosInstance.get(url);
                this.accessOperations = res.data;
                AccessStore.minAmount = res.data.details.budget.min;
                AccessStore.maxAmount = res.data.details.budget.max;
                AccessStore.campaignDurationMin = res.data.details.campaign_duration.min;
                AccessStore.campaignDurationMax = res.data.details.campaign_duration.max;
                AccessStore.cpmFlated = res.data.details.cpm_flated;
                AccessStore.operationsMedias = res.data.details.medias;
                AccessStore.purchaseDelay = res.data.details.purchase_delay;
                AccessStore.bannerPreviewUrl = res.data.details.banner_preview_url;
                if (!res.data.details.products_show) {
                    AccessStore.selectedOperations.push(res.data.details.products_list[0]);
                }
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
    },
});
