<template>
    <b-navbar class="access-navbar" toggleable="lg">
        <b-navbar-nav class="m-auto">
            <img :src="brandLogo" :alt="'logo_' + globalStore.brand" />
        </b-navbar-nav>
    </b-navbar>
</template>

<script setup lang="ts">
    import { useGlobalStore } from '@/store/GlobalStore';
    const globalStore = useGlobalStore();
    const brandLogo = `${process.env.VUE_APP_RESOURCES_URL}/brandLogo/${globalStore.brand}--fullsize.svg`;
</script>

<style lang="scss" scoped>
    .access-navbar {
        min-height: 64px;
        background: #ffffff;
        border: 1px solid #dbdbdb;
    }
</style>
