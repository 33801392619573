import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "second-bloc mb-4" }
const _hoisted_2 = { class: "fsb-typo-heading-5" }
const _hoisted_3 = {
  for: "tags-zipCode",
  class: "sub-title fsb-typo-para-regular"
}

import { useAccessDataStore } from '@/store/accessData';
    import { storeToRefs } from 'pinia';
    import { useI18n } from 'vue-i18n';

    
export default /*@__PURE__*/_defineComponent({
  __name: 'DiffusionZone',
  setup(__props) {

    const { t } = useI18n({ useScope: 'global' });

    const accessData = useAccessDataStore();

    let { zipCodes } = storeToRefs(accessData);
    let zipCodesTags = [''] as string[];
    let lastEventDate = 0;

    const onTagAdded = (tags: string[]) => {
        zipCodes.value = tags;
    };

    const tagValidator = (tag: string) => {
        // Individual tag validator function
        const validTag = !!tag.match(/^\d{5}$/);
        if (validTag) triggerEnterKey();
        return validTag;
    };

    const triggerEnterKey = () => {
        const currentDate = new Date().getTime();
        const diff = currentDate - lastEventDate;
        if (diff < 300) return;
        lastEventDate = currentDate;
        const input = document.querySelector('#tags-zipCode');
        if (input) {
            const event = new KeyboardEvent('keydown', {
                key: 'Enter',
                code: 'Enter',
                keyCode: 13,
                which: 13,
            });
            input.dispatchEvent(event);
        }
    };

return (_ctx: any,_cache: any) => {
  const _component_b_form_tags = _resolveComponent("b-form-tags")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.$t('chooseDiffusionArea')), 1),
    _createElementVNode("label", _hoisted_3, [
      _createTextVNode(_toDisplayString(_ctx.$t('choiceDiffusionArea')) + " ", 1),
      _cache[1] || (_cache[1] = _createElementVNode("span", null, "●", -1))
    ]),
    _createVNode(_component_b_form_tags, {
      "input-id": "tags-zipCode",
      placeholder: _ctx.$t('enterYourPostCode'),
      separator: " ,;",
      "tag-validator": tagValidator,
      modelValue: _unref(zipCodesTags),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(zipCodesTags) ? (zipCodesTags).value = $event : zipCodesTags = $event)),
      "duplicate-tag-text": _unref(t)('zipCodeDuplicateRule'),
      onInput: onTagAdded,
      "invalid-tag-text": _unref(t)('zipCodeFormatRule')
    }, null, 8, ["placeholder", "modelValue", "duplicate-tag-text", "invalid-tag-text"])
  ]))
}
}

})