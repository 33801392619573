const formatedDateToFront = (date: Date): string => date.toLocaleDateString('fr-FR');

const formatedDateToBack = (date: Date): string => date.toISOString().split('T')[0];

const addDays = (days: number): Date => {
    const today = new Date();
    today.setDate(today.getDate() + days);

    return today;
};

export { formatedDateToFront, formatedDateToBack, addDays };
