import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "third-bloc" }

import AdsList from '../Campaigns/AdsList.vue';
    import BudgetsRecap from '../Campaigns/BudgetsRecap.vue';
    import BudgetSlider from '../Campaigns/BudgetSlider.vue';
    import DiffusionZone from '../Campaigns/DiffusionZone.vue';
    import { storeToRefs } from 'pinia';
    import { onMounted } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { useAccessOperationsStore } from '@/store/accessOperations';

    
export default /*@__PURE__*/_defineComponent({
  __name: 'LeftContainer',
  setup(__props) {

    const route = useRoute();
    const router = useRouter();

    const accessOperationsStore = useAccessOperationsStore();

    const { accessOperations } = storeToRefs(accessOperationsStore);

    onMounted(async () => {
        const token = route.params.token as string;
        try {
            await accessOperationsStore.getAccessOperations(token);
        } catch {
            router.push({ name: 'error' });
        }
    });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("ol", null, [
    (_unref(accessOperations).details?.products_show)
      ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
          _createVNode(AdsList)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("li", null, [
      _createVNode(DiffusionZone)
    ]),
    _createElementVNode("li", null, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(BudgetSlider),
        _createVNode(BudgetsRecap)
      ])
    ])
  ]))
}
}

})