import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["alt"]

import { useGlobalStore } from '@/store/GlobalStore';
    
export default /*@__PURE__*/_defineComponent({
  __name: 'AccessNavbar',
  setup(__props) {

    const globalStore = useGlobalStore();
    const brandLogo = `${process.env.VUE_APP_RESOURCES_URL}/brandLogo/${globalStore.brand}--fullsize.svg`;

return (_ctx: any,_cache: any) => {
  const _component_b_navbar_nav = _resolveComponent("b-navbar-nav")!
  const _component_b_navbar = _resolveComponent("b-navbar")!

  return (_openBlock(), _createBlock(_component_b_navbar, {
    class: "access-navbar",
    toggleable: "lg"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_b_navbar_nav, { class: "m-auto" }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: brandLogo,
            alt: 'logo_' + _unref(globalStore).brand
          }, null, 8, _hoisted_1)
        ], undefined, true),
        _: 1
      })
    ], undefined, true),
    _: 1
  }))
}
}

})