<template>
    <ol>
        <li v-if="accessOperations.details?.products_show">
            <ads-list></ads-list>
        </li>
        <li>
            <diffusion-zone></diffusion-zone>
        </li>
        <li>
            <div class="third-bloc">
                <budget-slider></budget-slider>
                <budgets-recap></budgets-recap>
            </div>
        </li>
    </ol>
</template>

<script lang="ts" setup>
    import AdsList from '../Campaigns/AdsList.vue';
    import BudgetsRecap from '../Campaigns/BudgetsRecap.vue';
    import BudgetSlider from '../Campaigns/BudgetSlider.vue';
    import DiffusionZone from '../Campaigns/DiffusionZone.vue';
    import { storeToRefs } from 'pinia';
    import { onMounted } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { useAccessOperationsStore } from '@/store/accessOperations';

    const route = useRoute();
    const router = useRouter();

    const accessOperationsStore = useAccessOperationsStore();

    const { accessOperations } = storeToRefs(accessOperationsStore);

    onMounted(async () => {
        const token = route.params.token as string;
        try {
            await accessOperationsStore.getAccessOperations(token);
        } catch {
            router.push({ name: 'error' });
        }
    });
</script>

<style lang="scss">
    li {
        color: white !important;
        font-weight: 700 !important;
    }
    h5 {
        color: white !important;
        font-weight: 700 !important;
        line-height: 18px !important;
        margin-bottom: 10px !important;
    }
    .sub-title {
        color: white !important;
        font-weight: 700 !important;
        span {
            color: red;
        }
    }
</style>
