import { defineStore } from 'pinia';
import { accessStoreState } from '@/interfaces/stores/accessStoreState';
import { addDays, formatedDateToFront } from '@/helpers/date';

export const useAccessDataStore = defineStore('useAccessDataStore', {
    state: () =>
        ({
            selectedOperations: [],
            zipCodes: [],
            budget: 0,
            minAmount: 20000,
            maxAmount: 30000,
            campaignDurationMin: 2,
            campaignDurationMax: 10,
            cpmFlated: 2.5,
            operationsMedias: [],
            purchaseDelay: 3,
            bannerPreviewUrl: '',
        } as accessStoreState),
    getters: {
        minBudget(state): number {
            return state.minAmount * state.selectedOperations.length;
        },
        maxBudget(state): number {
            return state.maxAmount * state.selectedOperations.length;
        },
        budgetInEuros(state): number {
            return state.budget / 100;
        },
        impressionsCount(state): number {
            return Math.round((this.budgetInEuros * 1000) / (state.cpmFlated / 100));
        },
        getCampaignStartDate(state): Date {
            const startDate = addDays(state.purchaseDelay);
            return startDate;
        },
        getCampaignEndDate(state): Date {
            const endDate = addDays(state.purchaseDelay + state.campaignDurationMax);
            return endDate;
        },
        getMediasToDisplay(state): string[] {
            return state.operationsMedias
                .filter((media) => media.threshold < state.budget)
                .sort((a, b) => a.threshold - b.threshold)
                .map((media) => media.code);
        },
        formatedStartDate(): string {
            return formatedDateToFront(this.getCampaignStartDate);
        },
    },
    actions: {},
});
